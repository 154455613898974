import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

const Appraisals = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "appraisals" }} />
      <Layout>
        <Container fluid className="info">
          <Container className="info-contain">
            <Row>
              <Col md={12}>
                <h4 className="info-title">About Our Appraisals</h4>
              </Col>
              <Col md={12} lg={10}>
                <p className="info-text-large">
                  We provide online appraisals that comply with the professional
                  standards of USPAP, the Uniform Standards of Professional
                  Appraisal Practice, and the Appraiser’s Association of
                  America.
                </p>
                <p className="info-text-intro">
                  Carla Young Fine Arts Appraisals LLC has extensive experience
                  in providing:
                </p>
                <ul className="info-list">
                  <li className="pb-2">
                    A current appraisal of your art written by an appraiser who
                    has high professional standards and qualifications, and who
                    will provide you with an expert, non-biased report.
                  </li>
                  <li className="pb-2">
                    The appraiser has access to art price databases; auction and
                    sales reports; an extensive library of research books and
                    catalogs; contacts at national and international auction
                    houses, galleries, and retail venues; and current
                    information about market trends.
                  </li>
                  <li className="pb-2">
                    The appraiser is knowledgeable, thorough and diligent in
                    research, appraisal writing, and methodology.
                  </li>
                </ul>
              </Col>
              <Col md={12} lg={10}>
                <p className="info-text-intro">Fees:</p>
                <ul className="info-list">
                  <li className="pb-2">
                    Charges are based on an hourly fee. Extra fees will be
                    charged for additional expenses including consultations with
                    experts.
                  </li>
                  <li className="pb-2">
                    Fees are not based upon the value of the art being
                    appraised, nor are they based upon any agreement regarding
                    consultation for brokerage services.
                  </li>
                  <li className="pb-2">
                    Fee for each appraisal will be provided and agreed upon with
                    the client prior to appraisal.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </>
  )
}

export default Appraisals
